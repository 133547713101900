require('./bootstrap');
require('jquery.easing/jquery.easing.min.js');
const bodymovin = require('lottie-web/build/player/lottie.js');
require('leaflet/src/Leaflet.js');
global.fullpage = global.fullpage = require('fullpage.js');
import 'fullpage.js/vendors/scrolloverflow.min.js';
require('inputmask/dist/jquery.inputmask.min.js');
var DG = require('2gis-maps');
require('./protection');

  $(window).on('load', function () {
      $('#loading').fadeOut().end().delay(400);
    });

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
	var timeanim2;
	var timeanim3; 
	var cloudinterval; 
	var cloud1;
	var cloud2;
	
	var t1;
	var t2;
	var t3;
	var t4;
	var t6;
	
	var s2t1;
	var s2t2;
	var s2t3;
	
	
	var s3t1;
	var s3t2;
	var s3t3;
	
	var s4t1;
	var s4t2;
	var s4t3;
	var s4t4;
	var s4t5;
	var clicked;
	var tr1;
	
	var s5t1;
	var rano = 0;
	var map;
	
		function GetDoc(docname){	
		fullpage_api.destroy('all');
		
		if ($('.closedoc').hasClass('closedoc_full') !== true){
			$('.closedoc').addClass('closedoc_full');
		
		$('.closedoc_full').on('click',function(){
		$('.closedoc').removeClass('closedoc_full');
			location.reload();
		});
		}
		
		
		
	$.get( "/"+docname, function( data ) {
		//var temp = jQuery.parseJSON(data);
		$( "document-title" ).html( data['title']);
		$( ".document-body" ).html( data['text']);
		$('#blockmodal').modal('show');		
	});		
	}
	
	function GetBlock(id){	
	$.get( "/block/"+id, function( data ) {
		
		$( ".modal-header" ).remove();
		$('.modal-content').removeClass('h100');
		
		 if (data['img'] !== 0){
		 $( ".modal-dialog" ).prepend('<div class="modal-header"><img src="/storage/'+data['img']+'" ></img></div>'); 
		 $('.modal-content').addClass('h100');
		 }  ;
		
		
		$( ".document-title" ).html(data['title']);
		$( ".document-body" ).html( data['bigtext']);
		$('#blockmodal').modal('show');		
	});		
	}
	
	function Maps(){
		
		if (map === undefined){
		
		map = DG.map('map', {
                    center: [43.121032,131.879136],
                    zoom: 16,
					preferCanvas: true,
					zoomControl : false,
					fullscreenControl: false,
					closePopupOnClick: false,
                });
		var popupContent = '<div class="leaflet-popup-content-wrapper"><div class="leaflet-popup-content" ><div class="dg-popup__container-wrapper"><div class="dg-popup__container" dir="auto"><div id="module-1-1-2" data-module="firmCallout" class="callout _module_firmCallout _emptyTitle _entrance _wideButton"><address class="callout__address">Фонтанная,&nbsp;6<span class="callout__addressComment"> — 211 офис; 2 этаж</span></address><div class="callout__phone">+7 (423) 262‒02‒77</div><div class="callout__workhours"><a href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" target="_blank" class="link  callout__scheduleToday _scheme_dark">Рабочие дни <span class="callout__scheduleTodayTime">10:00–19:00</span></a></div><div class="callout__workhours"><a href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" target="_blank" class="link  callout__scheduleToday _scheme_dark">Выходные дни <span class="callout__scheduleTodayTime">10:00–12:00</span></a></div><footer class="callout__footer"><div class="callout__btn _details"><a target="_blank" href="http://2gis.ru/vladivostok/firm/70000001006560971/center/131.879173,43.121085/zoom/17?utm_medium=widget&amp;utm_campaign=firmsonmap&amp;utm_source=details" class="link  callout__btnIn callout__details _scheme_none">Узнать больше</a></div></footer></div></div></div></div></div><svg class="leaflet-popup-tip-container leaflet-popup-tip-container_svg"><path d="M0 0c12.643 0 28 7.115 28 44h2c0-36.885 15.358-44 28-44h-58z"></path></svg>';	

			var popup = DG.popup({'minWidth':400}).setContent(popupContent);
	
		DG.marker([43.121032,131.879136]).addTo(map).bindPopup(popup).openPopup();
		//map.openPopup();
		}
	}
	
	var animation = bodymovin.loadAnimation({
	  container: document.getElementById('bm'),
	  renderer: 'svg',
	  loop: false,
	  autoplay: false,
	  path: './animation/data.json'
	})
	
	var plays21 = bodymovin.loadAnimation({
			  container: document.getElementById('playsection2-1'),
			  renderer: 'svg',
			  loop: false,
			  autoplay: false,
			  path: './animation/02_start.json'
			});
			
		var plays22 = bodymovin.loadAnimation({
				  container: document.getElementById('playsection2-2'),
				  renderer: 'svg',
				  loop: true,
				  autoplay: false,
				  path: './animation/02_loop.json'
				});
		
		var plays31 = bodymovin.loadAnimation({
			  container: document.getElementById('playsection3-1'),
			  renderer: 'svg',
			  loop: false,
			  autoplay: false,
			  path: './animation/03_start.json'
			});
			
		var plays32 = bodymovin.loadAnimation({
				  container: document.getElementById('playsection3-2'),
				  renderer: 'svg',
				  loop: true,
				  autoplay: false,
				  path: './animation/03_loop.json'
				});	


	function StopAnimationSection1(){
		clearInterval(cloudinterval);	
		
		setTimeout(function () {
			cloud1.destroy();
			cloud2.destroy();
			animation.stop();	
		}, 700);	
	
	}
	
	
	function StartAnimationSection1(){		
		
		$('#bm').css('margin-top','0');
		animation.play();		
		RenderCloud();	
	
		

		 cloudinterval = setInterval(function(){
			 RenderCloud();
		 }, 19000);
	}
	
	
	function StopAnimationSection2(){
		clearTimeout(timeanim2);	
		
		setTimeout(function () {
		$("#playsection2-1").css("opacity", "0");
		$("#playsection2-2").css("opacity", "0");
		plays21.stop();
		plays22.stop();	
		}, 700);	
	
	}
	
		
	function StopAnimationSection3(){
		clearTimeout(timeanim3);	
		setTimeout(function () {
		$("#playsection3-1").css("opacity", "0");
		$("#playsection3-2").css("opacity", "0");
		plays31.stop();
		plays32.stop();	
		}, 700);
		
	
	}
	
	function AnimationSection2(){
	
			$("#playsection2-1").css("opacity", "1");
			plays21.play();
			
			timeanim2 = setTimeout(function () {
				plays22.play();
				$("#playsection2-2").css("opacity", "1");
				$("#playsection2-1").css("opacity", "0");
				},  13000);
	}
	
		
	function AnimationSection3(){
	
			$("#playsection3-1").css("opacity", "1");
			plays31.play();
			
			timeanim3 = setTimeout(function () {
				plays32.play();
				$("#playsection3-2").css("opacity", "1");
				$("#playsection3-1").css("opacity", "0");
				},  33000);
	}

	
		

	function Vcloud(id, cloud_class, style_init, style_to){	
		$('#'+id).remove();
		$('<div class="cloud '+cloud_class+'" style="'+style_init+'" id="'+id+'"> </div>').appendTo('.cloud_run');
		var lll = bodymovin.loadAnimation({
		  container: document.getElementById(id),
		  renderer: 'svg',
		  loop: true,
		  autoplay: false,
		  path: './animation/cloud01.json'
		});
		
		eval(id+"= lll");
		setTimeout(function(){$('#'+id).css('left',style_to);}, 500);	
	}		
	
	

	function RenderCloud(){	
		setTimeout(function(){
		Vcloud('cloud1', 'left',  'top: '+Math.floor((Math.random() * 100) + 1)+'%;', '100%');
		cloud1.play();
		}, 5000);
		Vcloud('cloud2', 'right',  'top: '+Math.floor((Math.random() * 100) + 1)+'%;', '-100%');	
		
		cloud2.play();	
	}	

	function viewMessageForm(){
		$('.message-form').addClass('active');
		$('.wrapper-right').addClass('dark');
		$('.script-transform-right').addClass('script-active');
	}
	
	function hideMessageForm(){
		$('.message-form').removeClass('active');
		$('.wrapper-right').removeClass('dark');
		$('.script-transform-right').removeClass('script-active');
	}
	
	function hideAddressForm(){
		$('.address-form').removeClass('active');
		$('.script-transform-left').removeClass('script-active');
		$('.wrapper-left').removeClass('dark');
	}
	
	function viewAddressForm(){
		$('.address-form').addClass('active');
		$('.script-transform-left').addClass('script-active');
		$('.wrapper-left').addClass('dark');
	}
	
function textSection1anim(){		
		
		
		$('.s1textanim2').css({"transition": "all 1s ease"});		
		t2 = setTimeout(function(){$('.s1textanim2').css('opacity','1');}, 1000);
		
		$('.s1textanim4').css({"transform":"translateX(-400px)"}).css({"transition": "all 1s ease"});
		t3 = setTimeout(function(){$('.s1textanim4').css({"transform":"translateX(0) "}).css('opacity','1');}, 1000);
		
		$('.s1textanim1').css({"transition": "all 1s ease"});		
		t1 = setTimeout(function(){$('.s1textanim1').css({"transform":"unset"}).css('opacity','1');}, 1300);
		
		$('.s1textanim6').css({"transform":"translateX(-400px)"}).css({"transition": "all 1s ease"});
		t6 = setTimeout(function(){$('.s1textanim6').css({"transform":"translateX(0)"}).css('opacity','1');}, 1500);
		
		
		
		
		
		
		$('.s1textanim5').css({"transform":"translateY(500px)"}).css({"transition": "all 2s ease"});
		t4 = setTimeout(function(){$('.s1textanim5').css({"transform":"unset"}).css('opacity','1');}, 1900);

		//$('.s1textanim3').css({"transform":"translateX(500px)"}).css({"transition": "all 2s ease"});
		//setTimeout(function(){$('.s1textanim3').css({"transform":"unset"}).css('opacity','1');}, 7000);
		
	}
	
	function textSection1animReset(){
		
		clearTimeout(t1);
		clearTimeout(t2);
		clearTimeout(t3);
		clearTimeout(t4);
		$('.s1textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(400px)"});
		$('.s1textanim2').css({"transition": "all .1s ease"}).css('opacity','0');
	//	$('.s1textanim3').css({"transition": "all .1s ease"}).css('opacity','0');
		$('.s1textanim4').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateX(-400px)"});
		$('.s1textanim6').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(-400px)"});
		$('.s1textanim5').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(500px)"});
		
	}

	function textSection2anim(){		
		$('.s2textanim1').css({"transition": "all 1s ease"});		
		s2t1 = setTimeout(function(){$('.s2textanim1').css({"transform":"unset"}).css('opacity','1');}, 500);		
		$('.s2textanim2').css({"transition": "all 1s ease"});		
		s2t2 = setTimeout(function(){$('.s2textanim2').css({"transform":"unset"}).css('opacity','1');}, 500);		
		$('.s2textanim3').css({"transition": "all 1s ease"});
		s2t3 = setTimeout(function(){$('.s2textanim3').css({"transform":"unset"}).css('opacity','1');}, 1000);		
	}
	
	function textSection2animReset(){
		clearTimeout(s2t1);
		clearTimeout(s2t2);
		clearTimeout(s2t3);
		$('.s2textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(-500px)"});
		$('.s2textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateX(500px)"});
		$('.s2textanim3').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform":"translateY(400px)"});		
	}	
	
	function textSection3anim(){		
		$('.s3textanim1').css({"transition": "all 1s ease"});		
		$('.s3textanim2').css({"transition": "all 1s ease"});		
		$('.s3textanim3').css({"transition": "all 1s ease"});		
		s3t1 = setTimeout(function(){$('.s3textanim1').css({"transform":"rotate(-90deg)"}).css('opacity','1');}, 500);		
		s3t2 = setTimeout(function(){$('.s3textanim2').css({"transform":"unset"}).css('opacity','1');}, 800);		
		s3t3 = setTimeout(function(){$('.s3textanim3').css({"transform":"unset"}).css('opacity','1');}, 1000);		
	}
	
	function textSection3animReset(){
		clearTimeout(s3t1);
		clearTimeout(s3t2);
		clearTimeout(s3t3);
		$('.s3textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "rotate(-180deg)"});
		$('.s3textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-100px)"});
		$('.s3textanim3').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-100px)"});
		
	}
	
	
	function textSection4anim(){		
		//$('.s4textanim1').css({"transition": "all 1s ease"});		
		//$('.s4textanim2').css({"transition": "all 1s ease"});		
	//	s4t1 = setTimeout(function(){$('.s4textanim1').css({"transform":"translateY(0px) rotate(180deg)"}).css('opacity','1');}, 500);		
	//	s4t2 = setTimeout(function(){$('.s4textanim2').css({"transform":"translateY(0px) rotate(180deg)"}).css('opacity','1');}, 500);	
	
	}
	
	function textSection4animReset(){
		clearTimeout(s4t1);
		clearTimeout(s4t2);
		clearTimeout(s4t3);
		clearTimeout(s4t4);
		clearTimeout(s4t5);

	//	$('.s4textanim1').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(800px) rotate(180deg)"});
	//	$('.s4textanim2').css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateY(-800px) rotate(180deg)"});
		
		
	}
	
	function AnimationSection4(){
		
		var index = 0;
		
		s4t4 = setInterval(function(){
		 $('.sect4_left').find('.advice-panel').eq(index).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});		  
		  $('.sect4_left').find('.advice-panel').eq(index+2).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});
		  index++;
		  if(index > $('.sect4_left').find('.advice-panel').length){
		  clearInterval(s4t4);
		  clearInterval(s4t5);
		}
		}, 500);
		
		s4t5 = setInterval(function(){
		  var kkk = index+2;
		  		  
		  
		}, 500);
		
		s4t3 = setInterval(function(){
		$('.sect4_left').find('.advice-panel.left').children('.advice-body').children('.advice-body-back').css({"transition": "all 1s ease"}).css('transform','translateY(-10px) translateX(-10px)');
		$('.sect4_left').find('.advice-panel.right').children('.advice-body').children('.advice-body-back').css({"transition": "all 1s ease"}).css('transform','translateY(-10px) translateX(10px)');
		$('.sect4_left').find('.advice-panel.left').children('.advice-body').children('.body-text').css({"transition": "all 1s ease"}).css('transform','translateY(-20px) translateX(-20px)');
		$('.sect4_left').find('.advice-panel.right').children('.advice-body').children('.body-text').css({"transition": "all 1s ease"}).css('transform','translateY(-20px) translateX(20px)');		
		}, 3000);
		
	}
	
	function StopAnimationSection4(){
		
		setTimeout(function () {
		$('.advice-panel').each(function() {
				$( this ).css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(-800px)"});
				$( this ).children('.advice-body').children('.body-text').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
				$( this ).children('.advice-body').children('.advice-body-back').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
				//$(this).children('.advice-body').css({"transition": "all .1s ease"}).children('.body-text').css('top','10px');
			});
		$('.advice.right').children('.advice-panel').each(function() {
				$( this ).css({"transition": "all .1s ease"}).css('opacity','0').css({"transform": "translateX(800px)"});
			});
		
		
		//$('.advice-panel').children('.advice-body').children('.body-text').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');
		$('.advice-panel').children('.advice-body').children('.advice-body-back').css({"transition": "all .1s ease"}).css('transform','translateX(0px) translateY(0px)');

		
		
	
		}, 700);	
		
		
	}
	
	

	
	function animS1(){
		textSection1anim(); 
		 //0.5 появление логотипа + 
		setTimeout(function(){StartAnimationSection1()}, 500);		
	
		if ($('.arrow7l').not('.active')){
			setTimeout(function(){$('.arrow7l').addClass('active');}, 3000);	
		} 
		$('.arrow7l').off("click", null);
		$('.arrow7l').removeClass('revers');
		$('.arrow7l').on('click',function(){
			fullpage_api.moveSectionDown();
			clicked = false;
		});
			
				
	}
	
	function animS1reset(){
		setTimeout(function(){
			textSection1animReset();
			//$('.arrow7l').removeClass('active');
			
			}, 800);
		StopAnimationSection1();
	}
	
	function animS2(){
		 textSection2anim();
		
		setTimeout(function(){ AnimationSection2();}, 500);			
	}

	function animS2reset(){
		setTimeout(function(){textSection2animReset();}, 800);
		StopAnimationSection2();
	}
	
	
	function animS3(){
		textSection3anim(); 
		setTimeout(function(){AnimationSection3(); }, 500);					
	}

	function animS3reset(){
		setTimeout(function(){textSection3animReset();}, 800);
		StopAnimationSection3();
	}
	
	function resall(){
		
	}
	
	
	function ResetAll(){
		
		fullpage_api.moveTo(1);
		StopAnimationSection3();
		
		animS1();
	}
	
		
	function animS4(){
		//textSection4anim(); 
		//setTimeout(function(){AnimationSection4(); }, 1000);
		$('.arrow7l').off("click", null);
		
		$('.arrow7l').addClass('revers');		
		$('.arrow7l').on('click',function(){
			clicked = true;
				
		
			ResetAll();
		
		});
	}
	
	function animS4reset(){
		//setTimeout(function(){textSection4animReset();}, 800);
		//StopAnimationSection4();
		
		
	}
	
	function Fullpage7l(){
	
	 var myFullpage = new fullpage('#full', {
		//options here
		licenseKey: '48FA00F6-7E03411E-AE8B268C-9BD13321',
			css3:true,
		responsive: true,
		
		resize: true,
	//Custom selectors
	sectionSelector: '.section',
	slideSelector: '.slide',
	fixedElements: ['.nosc', '.arrow7l', '.menu','.modal'],
	
	onLeave: function(origin, destination, direction){
		if(origin.index == 1 && direction =='down'){				
				animS2reset();
				animS4();
				SectAnim(3);
				setTimeout(function(){
					animS3();
				},1100);
			}
			else if(origin.index == 1 && direction == 'up'){
				animS2reset();			
				
				if (!clicked){				
				
				SectAnim(1);
				setTimeout(function(){
					animS1();
				},1100);
				};	
				
			}			
			else if(origin.index == 0 && direction == 'down'){				
				animS1reset();
				SectAnim(2);
				setTimeout(function(){
					animS2();
				},1100);
			}		
			
			else if(origin.index == 2 && direction == 'up'){
				if (!clicked){
				
				SectAnim(2);
				setTimeout(function(){
					animS2();
				},1100);
				};	
				animS3reset();	
				
					
			}			
			
			
				
			
	
	},
	afterRender: function(origin, destination, direction){
		//	animS2reset();
		//	animS3reset();
		//	animS4reset();
	
	},
		
	});

	}


	
	$(window).resize(function(){
	if ($(window).width() > $(window).height()) {

        $.get( "/desktop", function( data ) {
		location.reload();
		});
			
		//	Fullpage7l();

        } 
	});	

	
	function SectAnim(id){
		$('.sect'+id+'_left').css('transition',' all .1s ease').css('left','120%').css('position','relative');
		$('.sect'+id+'_right').css('transition',' all .1s ease').css('left','-120%').css('position','relative');	
		
	
		setTimeout(function(){		
		$('.sect'+id+'_right').css('transition',' all 1s ease').css('left','0%').css('position','relative');
		$('.sect'+id+'_left').css('transition',' all 1s ease').css('left','0%').css('position','relative');		                                     
		}, 500);		
	}
	
	
$(document).ready(function() {
	
	Fullpage7l();
	$('#sendmessage').on('click',function(e){
		 var formdata = $('.message7l').serialize(); // here $(this) refere to the form its submitting
	
    $.ajax({
        type: 'POST',
        url: "/message",
        data: formdata, // here $(this) refers to the ajax object not form
        success: function (data) {
           $( ".block-modal-title" ).html('Успешная отправка');
			$( ".block-modal-text" ).html('Ваше сообщение успешно отправлено');
			$('#blockmodal').modal('show');	
        },
    });
	e.preventDefault(); 
	
	});
	$('.blockmodal').on('click',function(){
		 GetBlock($(this).attr('data-id'));
	
	 });
	
	$("#phone").inputmask("+7(999)999-99-99")
	
	$('.menu-li').on('click',function(){
		view_page($(this).attr('data-id'));
	});	
	
	$('.closedoc').on('click',function(){
		//Fullpage7l();
		textSection1animReset();
		
	
	 setTimeout(function(){
	 SectAnim(1);
	 animS1();
	
	 }, 500);
	
	});
	
	$('.click_sect').on('click',function(){
		view_page($(this).attr('data-id'));
		//console.log($(this).attr('data-id'));
	});
	
	
	
	
	$(".form-check-input").change(function() {
    if(this.checked) {
        $('.sendbut').removeAttr('disabled');
    } else {
		  $('.sendbut').attr("disabled", true);
	}
	});
	
	
	$('.doclegal').on('click',function(){
		GetDoc('legal');
	});
	
	
	$('.doccontract').on('click',function(){
		GetDoc('contract_pr');
	});
	
	
	
	var $toggleButton = $('.toggle-button'),
        $menuWrap = $('.menu-wrap');
		$toggleButton.on('click', function() {
        $(this).toggleClass('button-open');
        $menuWrap.toggleClass('menu-show');		
    });
	
	$('.menu').on('click',function(){
		
		if ($('.menu-list').hasClass('show1')){
			$('.menu-list').css('left','-100%').css('transition',' all .5s ease').css('opacity','0');
		} else {
			$('.menu-list').css('left','0%').css('transition',' all .5s ease').css('opacity','1').css('z-index','999');
		}
		clearTimeout(tr1);
		
	
		$('.menu').toggleClass('m-top-15',400);
		$('.menu-list').toggleClass('show1');
		
		$('.menu-li').toggleClass('show-li');
		
		$('.menu-wrapper').toggleClass('wrapper-show');

		
		$('.arrow7l').toggleClass('dnone');
		hideAddressForm();
		hideMessageForm();
		
		if ($('.menu-list').hasClass('menu-show')) {			
			
		
			$('.pages.active').addClass('pages-menu-active');
		} else {
			if (!$('.pages').hasClass('active')){
			
			
			}
			$('.pages').removeClass('pages-menu-active');
			$('.trans-form').removeClass('active');
			
		}
		
	});

	
	
	textSection1animReset();
	
	
	 setTimeout(function(){
	 SectAnim(1);
	 animS1();
	
	 }, 500);
	
	
	
		function view_page(id){
			if (!$('.page'+id).hasClass('active')){
				
				$('.page'+rano).addClass('zed');
				setTimeout(function () {
					$('.page'+rano).removeClass('active').removeClass('zed');
					$('.page'+rano).find('.advice-body-back').removeAttr( 'style' );
					$('.page'+rano).find('.body-text').removeAttr( 'style' );
					$('.page'+rano).find('.page-panel').removeAttr( 'style' );
					$('.page'+rano).removeAttr( 'style' );
				
				} ,10);
			
			
			
			$('.pages.page'+id).css('opacity','1').css('left','0%').css('display','flex').addClass('active');
			
			setTimeout(function () {
				
				if ($('.menu').hasClass('button-open')){
					$('.menu').click(); 
					rano = id;
				} else {
					rano = id;
				}
				} ,700);
			$('.arrow7l').css('display','none');
			textSection1animReset();
			textSection2animReset();
			textSection3animReset();
			textSection4animReset();
			StopAnimationSection3();
			StopAnimationSection2();
			StopAnimationSection1();
			
			
			viewpage(id);
			
			}
		
	}
	
	function viewpage(idpage){
	fullpage_api.destroy('all');
	$('#full').css('display','none');
	Maps();
			
			var panels =  $('.page'+idpage+'.active').find('.page-panel');
			if (panels.length > 0){
			var leftpanel = $('.page'+idpage+'.active').find('.page-panel.left');
			var rightpanel = $('.page'+idpage+'.active').find('.page-panel.right');
			console.log('panels '+$(panels).length);
			var index2 = 0;		
				s5t1 = setInterval(function(){
				  $(panels).eq(index2).css({"transition": "all 1s ease"}).css('opacity','1').css({"transform": "translateX(0px)"});	
				  console.log(index2);
					if(index2 >= $(panels).length){
					  clearInterval(s5t1);		
					}
				  index2++;		  
				}, 500);	

			
		
	};
	}
	
	
	
});







